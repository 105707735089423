/* eslint no-else-return: 0 */
import { combineReducers } from 'redux'
import { createReducer } from 'utils'
import {
  SUBMIT_LOGOUT_USER,
  AUTHENTICATION_ERROR,
  STOP_AUTH
} from 'auth/constants'
import { TOGGLE_NAV, TOGGLE_NEWS_FEED } from './constants'

export const whiteList = [
  'HEARTBEAT',
  // 'PROPERTY_CHANGE',
  'NEWS_FEED',
  'FAILURE',
  'SUCCESS',
  'CANCELED',
  'ON_ADD',
  'REQUEST_CANCELED'
]

const resetTypes = [
  SUBMIT_LOGOUT_USER.SUCCESS,
  SUBMIT_LOGOUT_USER.FAILURE,
  AUTHENTICATION_ERROR,
  STOP_AUTH
]
const initialState = {
  isFetching: false,
  navOpen: false,
  newsFeedDisabled: false
}

export const isFetching = (state = false, action) => {
  const { payload = {}, type, meta = {} } = action
  if (whiteList.some(x => type.includes(x))) return false
  if (meta.apiRequest) return true
  if (type.includes('ADD_MODAL') && payload.type === 'warning') return false
  if (resetTypes.includes(type)) {
    return initialState.isFetching
  }
  return state
}

export const navOpen = (state = false, { type, payload }) => {
  if (resetTypes.includes(type)) {
    return initialState.navOpen
  }
  if (type === TOGGLE_NAV) {
    if (payload && payload.flag != null) {
      return payload.flag
    }
    return !state
  } else if (
    (type.includes('layout/ON_ADD') && payload?.name !== 'Dashboard') ||
    type.includes('layout/CHANGE_TAB')
  ) {
    // return false
    return state === true ? false : state
  }
  return state
}

export const newsFeedDisabled = (state = false, { type }) => {
  if (resetTypes.includes(type)) {
    return initialState.newsFeedDisabled
  }
  if (type === TOGGLE_NEWS_FEED) {
    return !state
  }
  return state
}

export default combineReducers({
  isFetching,
  navOpen,
  newsFeedDisabled
})
